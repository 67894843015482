import React, { Component } from 'react';
import { TouchableOpacity, Text, Image, ActivityIndicator, StyleSheet } from 'react-native';

export default class Button extends Component {
  render() {
    const { buttonColor, iconImageSource, disabled, loading, children, textStyle, style, ...props } = this.props;

    return (
      <TouchableOpacity
        disabled={disabled || loading}
        style={[
          styles.button,
          buttonColor ? { backgroundColor: buttonColor, backgroundImage: undefined } : null,
          style,
        ]}
        {...props}
      >
        {!!iconImageSource && (
          <Image
            source={iconImageSource}
            resizeMode={'contain'}
            style={styles.iconImage}
          />
        )}

        {loading && (
          <ActivityIndicator color={'#FFFFFF'} size={'small'} />
        )}

        {!loading && (
          <Text style={[ styles.buttonText, textStyle ]}>{children}</Text>
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundImage: 'linear-gradient(90deg, #A238E6 0%, #DE3975 77.6%, #EA877D 100%);',
    borderRadius: 6,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1);',
    flex: 1,
    justifyContent: 'center',
    minHeight: 36,
  },
  buttonText: {
    color: '#EEEFFB',
    fontFamily: 'SemiBold',
    fontSize: 14,
  },
  iconImage: {
    height: 23,
    left: 6,
    position: 'absolute',
    width: 23,
  },
});
