import React, { Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Button, RegularText } from './';

export default class PoweredByLogo extends Component {
  _openTwitter = () => {
  }

  _openDiscord = () => {
    const { game } = this.props;

    let [ url, query ] = window.location.href.split('?');

    query = query.split('#')[0]; // remove hash
    query = !query.includes('oauth=discord') ? `${query}&authService=discord` : query;

    window.location = 'https://discord.com/api/oauth2/authorize' +
                      `?client_id=${game.discordClientId}` +
                      '&response_type=token' +
                      '&scope=identify' +
                      `&redirect_uri=${encodeURIComponent(url)}` +
                      `&state=${encodeURIComponent(query)}`;
  }

  _openGoogle = () => {

  }

  render() {
    const { game, register, onExternalWalletPress, style } = this.props;

    return (
      <View style={[ styles.container, style ]}>
        <RegularText style={styles.text}>Or {register ? 'register' : 'sign in'} with</RegularText>

        <View style={styles.buttons}>
          <View style={styles.row}>
            {!!game.twitterClientId && (
              <Button
                onPress={this._openTwitter}
                buttonColor={'#ECE8EE'}
                style={styles.button}
              >
                <Image
                  resizeMode={'contain'}
                  source={require('../assets/images/twitter-icon.png')}
                  style={styles.icon}
                />
              </Button>
            )}

            {!!game.discordClientId && (
              <Button
                onPress={this._openDiscord}
                buttonColor={'#ECE8EE'}
                style={styles.button}
              >
                <Image
                  resizeMode={'contain'}
                  source={require('../assets/images/discord-icon.png')}
                  style={styles.icon}
                />
              </Button>
            )}

            {!!game.googleClientId && (
              <Button
                onPress={this._openGoogle}
                buttonColor={'#ECE8EE'}
                style={styles.button}
              >
                <Image
                  resizeMode={'contain'}
                  source={require('../assets/images/google-icon.png')}
                  style={styles.icon}
                />
              </Button>
            )}
          </View>

          <Button
            onPress={onExternalWalletPress}
            buttonColor={'#ECE8EE'}
            textStyle={styles.blackText}
            style={[ styles.button, styles.walletButton ]}
          >
            External Wallet
          </Button>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  blackText: {
    color: '#000000',
  },
  button: {
    borderColor: '#DDDEE0',
    borderWidth: 1,
    flex: 1,
    marginHorizontal: 5,
  },
  buttons: {
    marginHorizontal: -5,
  },
  icon: {
    height: 22,
    top: 2,
    width: 22,
  },
  row: {
    flexDirection: 'row',
  },
  text: {
    marginBottom: 6,
  },
  walletButton: {
    marginTop: 10,
  },
});
