import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { Helper } from 'react-native-maestro';

export default class WalletHelper extends Helper {
  static get instanceKey() {
    return 'walletHelper';
  }

  async connectMetaMaskGenerateSignature(gameId, playerCustodialWalletAddress) {
    const { signer, signerAddress } = await this.connectMetaMaskWallet(gameId);

    return this.generateDelegateSignature(gameId, playerCustodialWalletAddress, signer, signerAddress);
  }

  async connectMetaMaskWallet(gameId) {
    const metamaskProviderProxy = await detectEthereumProvider();
    let metamaskProvider;

    if (metamaskProviderProxy) {
      metamaskProvider = new ethers.providers.Web3Provider(metamaskProviderProxy, 'any');
    }

    try { // force wallet selection
      await metamaskProvider.send('wallet_requestPermissions', [
        { eth_accounts: {} },
      ]);
    } catch (error) {
      await metamaskProvider.send('eth_requestAccounts', []);
    }

    const signer = metamaskProvider.getSigner();
    const signerAddress = await signer.getAddress();

    return { signer, signerAddress };
  }

  async generateDelegateSignature(gameId, playerCustodialWalletAddress, signer, signerAddress) {
    const abiCoder = ethers.utils.defaultAbiCoder;
    const systemId = ethers.utils.id(gameId);
    const delegateAddress = playerCustodialWalletAddress;
    const nonce = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    const args = [ systemId, delegateAddress, true, signerAddress, nonce ];
    const hash = ethers.utils.keccak256(abiCoder.encode(
      [ 'bytes32', 'address', 'bool', 'address', 'uint256' ],
      args,
    ));

    return {
      signerAddress,
      nonce,
      signature: await signer.signMessage(ethers.utils.arrayify(hash)),
    };
  }

  async generateMessageSignature(message, signer) {
    return signer.signMessage(message);
  }
}
