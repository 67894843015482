import { Helper } from 'react-native-maestro';

const searchParams = new URLSearchParams(window.location.search.replace('?', ''));
const queryDark = searchParams.get('dark'); // game id

export default class UIHelper extends Helper {
  static get instanceKey() {
    return 'uiHelper';
  }

  isDarkMode() {
    return queryDark !== null;
  }
}
