import React, { Component } from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import maestro from '../maestro';

const { uiHelper } = maestro.helpers;

export default class TextButton extends Component {
  render() {
    const { style, children, ...props } = this.props;

    return (
      <TouchableOpacity style={[ styles.button, style ]} {...props}>
        <Text style={styles.buttonText}>{children}</Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  buttonText: {
    color: uiHelper.isDarkMode() ? 'rgba(236, 232, 238, 0.65)' : 'rgba(32, 32, 32, 0.6)',
    fontFamily: 'Normal',
    fontSize: 12,
  },
});
