import React, { Component } from 'react';
import { View, Text, Image, ActivityIndicator } from 'react-native';
import { AltSignInOptions, BrandedCard, Button, PageContainer, HeadingText, PoweredByLogo, RegularText, TextButton, TextField } from '../components';
import maestro from '../maestro';
import WithMobile from '../mobile';

const { metafabHelper, walletHelper } = maestro.helpers;

const querySearchParams = new URLSearchParams(window.location.search.replace('?', ''));
const queryGame = querySearchParams.get('game'); // game id
const queryFlow = querySearchParams.get('flow'); // login, loginConnect, register, registerConnect, forceConnect
const queryChain = querySearchParams.get('chain'); // chain for connect
const queryRedirectUri = querySearchParams.get('redirectUri');
const queryState = querySearchParams.get('state'); // state forwarded to redirectUri
const queryAuthService = querySearchParams.get('authService'); // oauth provider (discord, twitter, google, etc);

const fragmentSearchParams = new URLSearchParams(window.location.hash.slice(1));
const fragmentAccessToken = fragmentSearchParams.get('access_token');

const hash = window.location.hash ? window.location.hash.split('&') : null;
let hashState = hash ? hash.find(i => i.includes('state=')) : null;
hashState = hashState ? decodeURIComponent(hashState.split('=')[1]) : null;

let connectedSigner = null;

class GameAuthScreen extends Component {
  pageContainer = null;

  state = {
    game: undefined,
    player: undefined,
    username: '',
    password: '',
    confirmPassword: '',
    connectionWallet: '',
    showInit: true,
    showLogin: false,
    showRegister: false,
    showUsername: false,
    showConnectWallet: false,
    loading: false,
  }

  async componentDidMount() {
    if (hashState) {
      const originalHash = hash.filter(x => !x.includes('state=')).join('&');
      return window.location = `${window.location.href.split('#')[0]}?${hashState}${originalHash}`;
    }

    if (!queryGame) {
      return alert('The "game" query parameter must be included with a valid MetaFab game id.');
    }

    if (queryFlow && queryFlow.includes('Connect') && !queryChain) {
      return alert('The "chain" parameter must be included when using wallet connection flows.');
    }

    await this.loadGame();

    _insertWebTags(this.state.game);

    await this.validateGame();
    await this.prefetchAssets();

    if (queryAuthService) {
      this.handleAuthService();
    } else {
      this.loadPage();
    }
  }

  async loadGame() {
    const getGameResponse = await metafabHelper.getGame(queryGame);

    if (getGameResponse.statusCode !== 200) {
      await new Promise(() => alert(getGameResponse.body)); // block
    }

    await new Promise(resolve => {
      this.setState({ game: getGameResponse.body }, resolve);
    });
  }

  async validateGame() {
    const { game } = this.state;

    if (queryRedirectUri) { // validate provided redirect uri
      if (!metafabHelper.validateRedirectUri(game.redirectUris, queryRedirectUri)) {
        await new Promise(() => { // block
          alert(`${queryRedirectUri} does not match any allowed redirect uris for this game. Allowed base uris are ${game.redirectUris.join(', ')}`);
        });
      }
    }
  }

  async prefetchAssets() {
    const { game } = this.state;

    if (game.iconImageUrl || game.coverImageUrl) { // prefetch & wait 250ms
      if (game.iconImageUrl) {
        Image.prefetch(game.iconImageUrl);
      }

      if (game.coverImageUrl) {
        Image.prefetch(game.coverImageUrl);
      }

      await new Promise(resolve => setTimeout(resolve, 250));
    }
  }

  async loadPage() {
    const nextSection = [ 'register', 'registerConnect' ].includes(queryFlow) ? 'showRegister' : 'showLogin';
    this.setShowState(nextSection);

    await this.pageContainer.showBackground();
    await this.pageContainer.showContent();
  }

  async handleAuthService() {
    const { game } = this.state;
    const serviceName = queryAuthService;
    let serviceCredential;

    if (serviceName === 'discord') {
      serviceCredential = fragmentAccessToken;
    }

    if (!serviceCredential) {
      return this.loadPage();
    }

    const loginPlayerByServiceResponse = await metafabHelper.loginPlayerByService(game.publishedKey, serviceName, serviceCredential);

    if (loginPlayerByServiceResponse.statusCode !== 200) {
      alert(loginPlayerByServiceResponse.body);
      return this.loadPage();
    }

    const player = loginPlayerByServiceResponse.body;

    this.handleLoginNextScreen(player);
  }

  async handleAuthWallet() {
    const { game } = this.state;
    const { signer, signerAddress } = await walletHelper.connectMetaMaskWallet();
    const message = `Login to ${game.name} with wallet address ${signerAddress}`;
    const authSignature = await walletHelper.generateMessageSignature(message, signer);

    const loginPlayerByWalletResponse = await metafabHelper.loginPlayerByService(
      game.publishedKey,
      'wallet',
      JSON.stringify({ message, signature: authSignature }),
      signerAddress,
    );

    if (loginPlayerByWalletResponse.statusCode !== 200) {
      alert(loginPlayerByWalletResponse.body);
      return this.loadPage();
    }

    const player = loginPlayerByWalletResponse.body;
    const hasCustodialWallet = player.wallet.id === player.custodialWallet.id;

    if (([ 'loginConnect', 'registerConnect', 'forceConnect', 'connect' ].includes(queryFlow) && hasCustodialWallet) || queryFlow === 'forceConnect') {
      const { nonce, signature } = await walletHelper.generateDelegateSignature(game.id, player.custodialWallet.address, signer, signerAddress);
      this.connectMetaMaskWallet(player, signerAddress, nonce, signature);
    } else {
      this.finalize(player);
    }
  }

  async login() {
    const { game, username, password } = this.state;

    if (!username || !password) {
      return alert('A username and password must be provided.');
    }

    this.setState({ loading: true });

    const loginPlayerResponse = await metafabHelper.loginPlayer(game.publishedKey, username, password);

    if (loginPlayerResponse.statusCode !== 200) {
      this.setState({ loading: false });
      return alert(loginPlayerResponse.body);
    }

    const player = loginPlayerResponse.body;

    this.handleLoginNextScreen(player);
  }

  async register() {
    const { game, username, password, confirmPassword } = this.state;

    if (!username || !password || !confirmPassword) {
      return alert('A username, password and confirmed password must be provided.');
    }

    if (password !== confirmPassword) {
      return alert('Password and confirmed password do not match.');
    }

    this.setState({ loading: true });

    const createPlayerResponse = await metafabHelper.createPlayer(game.publishedKey, username, password);

    if (createPlayerResponse.statusCode !== 200) {
      this.setState({ loading: false });
      return alert(createPlayerResponse.body);
    }

    const player = createPlayerResponse.body;

    if ([ 'loginConnect', 'registerConnect', 'forceConnect', 'connect' ].includes(queryFlow)) {
      this.showConnectWallet(player);
    } else {
      this.finalize(player);
    }
  }

  async handleLoginNextScreen(player) {
    const hasCustodialWallet = player.wallet.id === player.custodialWallet.id;

    if (([ 'loginConnect', 'registerConnect', 'forceConnect', 'connect' ].includes(queryFlow) && hasCustodialWallet) || queryFlow === 'forceConnect') {
      this.pageContainer.showBackground();
      this.showConnectWallet(player);
    } else {
      this.finalize(player);
    }
  }

  async showConnectWallet(player) {
    await this.pageContainer.hideContent();
    this.setShowState('showConnectWallet', { player });
    await this.pageContainer.showContent();
  }

  async connectMetaMaskWallet(player = undefined, signerAddress = undefined, nonce = undefined, signature = undefined) {
    const { game } = this.state;
    player = player || this.state.player;

    if (!signerAddress && !nonce && !signature) {
      const sigResult = await walletHelper.connectMetaMaskGenerateSignature(
        game.id,
        player.custodialWallet.address,
      );

      signerAddress = sigResult.signerAddress;
      nonce = sigResult.nonce;
      signature = sigResult.signature;
    }

    this.setState({
      loading: true,
      connectionWallet: 'metamask',
    });

    const setPlayerConnectedWalletResponse = await metafabHelper.setPlayerConnectedWallet(
      player,
      signerAddress,
      nonce,
      signature,
      queryChain,
    );

    if (setPlayerConnectedWalletResponse.statusCode !== 200) {
      this.setState({ loading: false, connectionWallet: '' });
      return alert(setPlayerConnectedWalletResponse.body);
    }

    this.finalize(player);
  }

  async toggleLoginRegister() {
    await this.pageContainer.hideContent();
    this.setShowState(this.state.showLogin ? 'showRegister' : 'showLogin');
    await this.pageContainer.showContent();
  }

  finalize(player) {
    let hash = `accessToken=${player.accessToken}&id=${player.id}&walletDecryptKey=${player.walletDecryptKey}`;

    if (queryState) {
      hash += `&state=${queryState}`;
    }

    if (queryRedirectUri) {
      window.location = `${queryRedirectUri}#${hash}`;
    } else {
      window.location.hash = hash;
    }
  }

  setShowState(showKey, state = {}) {
    this.setState({
      username: '',
      password: '',
      confirmPassword: '',
      showInit: false,
      showLogin: false,
      showRegister: false,
      showConnectWallet: false,
      loading: false,
      ...{ [showKey]: true, ...state },
    });
  }

  _renderLogin = () => {
    const { styles } = this.props;
    const { game, username, password, loading } = this.state;

    return (
      <>
        <RegularText>Sign in to</RegularText>
        <HeadingText style={styles.headingText}>{game.name}</HeadingText>

        <TextField
          label={'Username'}
          onChangeText={username => this.setState({ username })}
          onSubmitEditing={() => this.login()}
          containerStyle={styles.field}
          value={username}
        />

        <TextField
          label={'Password'}
          onChangeText={password => this.setState({ password })}
          onSubmitEditing={() => this.login()}
          containerStyle={styles.field}
          secureTextEntry
          value={password}
        />

        <Button
          onPress={() => this.login()}
          buttonColor={game.primaryColorHex}
          loading={loading}
        >
          Sign in
        </Button>

        <AltSignInOptions
          game={game}
          onExternalWalletPress={() => this.showConnectWallet()}
          style={styles.altSignInContainer}
        />

        <TextButton onPress={() => this.toggleLoginRegister()} style={styles.textButton}>
          Need a {game.name} account? <Text style={styles.semiBoldText}>Sign up.</Text>
        </TextButton>
      </>
    );
  }

  _renderRegister = () => {
    const { styles } = this.props;
    const { game, username, password, confirmPassword, loading } = this.state;

    return (
      <>
        <RegularText>Sign up for</RegularText>
        <HeadingText style={styles.headingText}>{game.name}</HeadingText>

        <TextField
          label={'Username'}
          onChangeText={username => this.setState({ username })}
          onSubmitEditing={() => this.register()}
          containerStyle={styles.field}
          value={username}
        />

        <View style={styles.inlineFields}>
          <TextField
            label={'Password'}
            onChangeText={password => this.setState({ password })}
            onSubmitEditing={() => this.register()}
            containerStyle={[ styles.field, styles.inlineField ]}
            secureTextEntry
            value={password}
          />

          <TextField
            label={'Confirm Password'}
            onChangeText={confirmPassword => this.setState({ confirmPassword })}
            onSubmitEditing={() => this.register()}
            containerStyle={[ styles.field, styles.inlineField ]}
            secureTextEntry
            value={confirmPassword}
          />
        </View>

        <Button
          onPress={() => this.register()}
          buttonColor={game.primaryColorHex}
          loading={loading}
        >
          Sign up
        </Button>

        <AltSignInOptions
          register
          game={game}
          onExternalWalletPress={() => this.showConnectWallet()}
          style={styles.altSignInContainer}
        />

        <TextButton onPress={() => this.toggleLoginRegister()} style={styles.textButton}>
          Already have a {game.name} account? <Text style={styles.semiBoldText}>Sign in.</Text>
        </TextButton>
      </>
    );
  }

  _renderConnectWallet = () => {
    const { styles } = this.props;
    const { game, player, connectionWallet, loading } = this.state;
    const promptText = player
      ? `Hey ${player.username}! Connect your wallet to:`
      : 'Select a wallet to sign into:';

    return (
      <>
        <RegularText>{promptText}</RegularText>
        <HeadingText style={styles.headingText}>{game.name}</HeadingText>

        {(!connectionWallet || connectionWallet === 'metamask') && (
          <Button
            onPress={() => player ? this.connectMetaMaskWallet() : this.handleAuthWallet()}
            buttonColor={game.primaryColorHex}
            iconImageSource={require('../assets/images/metamask-icon.png')}
            style={styles.spacedWalletButton}
          >
            {!loading && 'MetaMask'}
            {loading && <ActivityIndicator color={'#FFFFFF'} />}
          </Button>
        )}

        {(!connectionWallet || connectionWallet === 'coinbase') && (
          <Button
            disabled
            buttonColor={game.primaryColorHex}
            iconImageSource={require('../assets/images/coinbase-wallet-icon.png')}
            style={[ styles.spacedWalletButton, styles.disabled ]}
          >
            {!loading && 'Coinbase Wallet'}
            {loading && <ActivityIndicator color={'#FFFFFF'} />}
          </Button>
        )}

        {(!connectionWallet || connectionWallet === 'walletConnect') && (
          <Button
            disabled
            buttonColor={game.primaryColorHex}
            iconImageSource={require('../assets/images/wallet-connect-icon.png')}
            style={styles.disabled}
          >
            {!loading && 'Wallet Connect'}
            {loading && <ActivityIndicator color={'#FFFFFF'} />}
          </Button>
        )}

        {!player && (
          <TextButton onPress={() => this.toggleLoginRegister()} style={styles.textButton}>
            Already have a {game.name} account? <Text style={styles.semiBoldText}>Sign in.</Text>
          </TextButton>
        )}
      </>
    );
  }

  render() {
    const { styles } = this.props;
    const { game, showInit, showLogin, showRegister, showConnectWallet } = this.state;

    return (
      <>
        <PageContainer
          showInit={showInit}
          backgroundImageUri={!!game && game.coverImageUrl}
          ref={ref => this.pageContainer = ref}
        >
          <BrandedCard
            iconImageUri={!!game && game.iconImageUrl}
            coverImageUri={!!game && game.coverImageUrl}
          >
            <View style={styles.content}>
              {showLogin && this._renderLogin()}
              {showRegister && this._renderRegister()}
              {showConnectWallet && this._renderConnectWallet()}
            </View>
          </BrandedCard>

          <PoweredByLogo style={styles.poweredByLogo} />
        </PageContainer>
      </>
    );
  }
}

export default WithMobile(GameAuthScreen, mobile => ({
  headingText: {
    marginTop: 2,
    marginBottom: 15,
  },
  textButton: {
    marginTop: 10,
  },
  content: {
    marginTop: 20,
  },
  field: {
    marginBottom: 20,
  },
  semiBoldText: {
    fontFamily: 'SemiBold',
  },
  spacedWalletButton: {
    marginBottom: 10,
  },
  poweredByLogo: {
    bottom: 0,
    position: 'absolute',
    zIndex: -1,
  },
  disabled: {
    opacity: 0.5,
  },
  altSignInContainer: {
    marginBottom: 10,
    marginTop: 20,
  },
  inlineFields: {
    flexDirection: 'row',
    marginHorizontal: -5,
  },
  inlineField: {
    flex: 1, marginHorizontal: 5,
  },
}));

function _insertWebTags(game) {
  document.title = game.name;

  _insertMetaTagName('twitter:card', 'summary_large_image');
  _insertMetaTag('twitter:title', game.name);
  _insertMetaTag('twitter:image', game.coverImageUrl || 'https://metafab-uploads.s3.amazonaws.com/coverDefault.jpg');
  _insertMetaTag('og:title', game.name);
  _insertMetaTag('og:image', game.coverImageUrl);
  _insertMetaTag('og:description', `Login, register or connect your wallet to ${game.name}.`);

  document.querySelector("link[rel='shortcut icon']").href = game.iconImageUrl || 'https://metafab-uploads.s3.amazonaws.com/iconDefault.jpg';
  document.querySelectorAll("link[rel='icon']").forEach(icon => icon.href = game.iconImageUrl || 'https://metafab-uploads.s3.amazonaws.com/iconDefault.jpg');
}

function _insertMetaTagName(name, content) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('name', name);
  metaTag.content = content;
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}

function _insertMetaTag(property, content) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('property', property);
  metaTag.content = content;
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}
