import React, { Component } from 'react';
import { View, Text, Image } from 'react-native';
import { BrandedCard, Button, PageContainer, HeadingText, PoweredByLogo, RegularText, TextButton, TextField } from '../components';
import maestro from '../maestro';
import WithMobile from '../mobile';

const { metafabHelper, permissionsHelper } = maestro.helpers;

const searchParams = new URLSearchParams(window.location.search.replace('?', ''));
const queryEcosystem = searchParams.get('ecosystem'); // ecosystem id
const queryGame = searchParams.get('game'); // game id
const queryFlow = searchParams.get('flow'); // login, loginConnect, register, registerConnect, forceConnect
const queryPermissions = searchParams.get('permissions');
const queryRedirectUri = searchParams.get('redirectUri');
const queryState = searchParams.get('state');

class EcosystemOAuthScreen extends Component {
  pageContainer = null;

  state = {
    ecosystem: undefined,
    game: undefined,
    profile: undefined,
    player: undefined,
    permissions: {},
    permissionComponents: [],
    username: '',
    password: '',
    confirmPassword: '',
    gameUsername: '',
    showInit: true,
    showLogin: false,
    showRegister: false,
    showPlayerRegister: false,
    showConnectWallet: false,
    showPermissions: false,
    loading: false,
  }

  async componentDidMount() {
    if (!queryEcosystem) {
      return alert('The "ecosystem" query parameter must be included with a valid MetaFab ecosystem id.');
    }

    if (!queryGame) {
      return alert('The "game" query parameter must be included with a valid MetaFab game id.');
    }

    let permissions;
    let permissionComponents = [];

    if (queryPermissions) {
      try {
        permissions = JSON.parse(queryPermissions);
        permissionsHelper.validateEcosystemPermissions(permissions);
        permissionComponents = await permissionsHelper.convertPermissionsToComponents(permissions);
      } catch (error) {
        return alert(`Permissions error: ${error.message}`);
      }
    }

    await this.loadEcosystem();
    await this.loadGame();
    await this.validateGame();
    await this.prefetchAssets();

    const { game } = this.state;
    const profile = await metafabHelper.getRememberedProfile() || undefined;
    let authProfilePlayerResponse;

    _insertWebTags(this.state.game);

    if (profile) { // handle remembered account & make sure it has an existing player
      authProfilePlayerResponse = await metafabHelper.authProfilePlayer(game.id, profile);

      if (authProfilePlayerResponse.statusCode !== 200) {
        authProfilePlayerResponse = await metafabHelper.createProfilePlayer(game.id, profile, profile.username);
      }
    }

    const player = authProfilePlayerResponse && authProfilePlayerResponse.statusCode === 200
      ? authProfilePlayerResponse.body
      : undefined;

    let nextScreen = 'showLogin';
    nextScreen = [ 'register', 'registerConnect' ].includes(queryFlow) ? 'showRegister' : nextScreen;
    nextScreen = profile && !player ? 'showPlayerRegister' : nextScreen;
    nextScreen = profile && player ? 'showPermissions' : nextScreen;

    this.setShowState(nextScreen, { profile, player, permissions, permissionComponents });

    await this.pageContainer.showBackground();
    await this.pageContainer.showContent();
  }

  async loadEcosystem() {
    const getEcosystemResponse = await metafabHelper.getEcosystem(queryEcosystem);

    if (getEcosystemResponse.statusCode !== 200) {
      await new Promise(() => alert(getEcosystemResponse.body)); // block
    }

    await new Promise(resolve => {
      this.setState({ ecosystem: getEcosystemResponse.body }, resolve);
    });
  }

  async loadGame() {
    const getGameResponse = await metafabHelper.getGame(queryGame);

    if (getGameResponse.statusCode !== 200) {
      await new Promise(() => alert(getGameResponse.body)); // block
    }

    await new Promise(resolve => {
      this.setState({ game: getGameResponse.body }, resolve);
    });
  }

  async validateGame() {
    const { game } = this.state;

    if (queryRedirectUri) { // validate provided redirect uri
      if (!metafabHelper.validateRedirectUri(game.redirectUris, queryRedirectUri)) {
        await new Promise(() => { // block
          alert(`${queryRedirectUri} does not match any allowed redirect uris for this game. Allowed base uris are ${game.redirectUris.join(', ')}`);
        });
      }
    }
  }

  async prefetchAssets() {
    const { ecosystem, game } = this.state;

    if (ecosystem.iconImageUrl || ecosystem.coverImageUrl || game.iconImageUrl) { // prefetch & wait 250ms
      if (ecosystem.iconImageUrl) {
        Image.prefetch(ecosystem.iconImageUrl);
      }

      if (ecosystem.coverImageUrl) {
        Image.prefetch(ecosystem.coverImageUrl);
      }

      if (game.iconImageUrl) {
        Image.prefetch(game.iconImageUrl);
      }

      await new Promise(resolve => setTimeout(resolve, 500));
    }
  }

  async login() {
    const { ecosystem, game, username, password } = this.state;

    if (!username || !password) {
      return alert('A username and password must be provided.');
    }

    this.setState({ loading: true });

    const loginProfileResponse = await metafabHelper.loginProfile(ecosystem.publishedKey, username, password);

    if (loginProfileResponse.statusCode !== 200) {
      this.setState({ loading: false });
      return alert(loginProfileResponse.body);
    }

    const profile = loginProfileResponse.body;

    await metafabHelper.setRememberedProfile(profile);

    const authProfilePlayerResponse = await metafabHelper.authProfilePlayer(game.id, profile);
    const player = authProfilePlayerResponse.statusCode === 200 ? authProfilePlayerResponse.body : undefined;
    const nextScreen = player ? 'showPermissions' : 'showPlayerRegister';

    await this.pageContainer.hideContent();
    this.setShowState(nextScreen, { profile, player });
    await this.pageContainer.showContent();
  }

  async register() {
    const { ecosystem, game, username, password, confirmPassword } = this.state;

    if (!username || !password) {
      return alert('A username and password must be provided.');
    }

    if (password !== confirmPassword) {
      return alert('Password and confirmed password do not match.');
    }

    this.setState({ loading: true });

    const createProfileResponse = await metafabHelper.createProfile(ecosystem.publishedKey, username, password);

    if (createProfileResponse.statusCode !== 200) {
      this.setState({ loading: false });
      return alert(createProfileResponse.body);
    }

    const profile = createProfileResponse.body;

    await metafabHelper.setRememberedProfile(profile);

    const createProfilePlayerResponse = await metafabHelper.createProfilePlayer(game.id, profile, username);
    const player = createProfilePlayerResponse.statusCode === 200 ? createProfilePlayerResponse.body : undefined;
    const nextScreen = player ? 'showPermissions' : 'showPlayerRegister';

    await this.pageContainer.hideContent();
    this.setShowState(nextScreen, { profile, player });
    await this.pageContainer.showContent();
  }

  async allowPermissions() {
    const { game, profile, player, permissions } = this.state;

    this.setState({ loading: true });

    const updateProfilePlayerResponse = await metafabHelper.updateProfilePlayer(game.id, profile, player, { permissions });

    if (updateProfilePlayerResponse.statusCode !== 200) {
      this.setState({ loading: false });
      return alert(updateProfilePlayerResponse.body);
    }

    permissionsHelper.rememberApprovedPermissions(profile.id, player.id, permissions);

    this.finalize(player);
  }

  async registerProfilePlayer() {
    const { game, profile, gameUsername } = this.state;

    if (!gameUsername) {
      return alert('A username must be provided.');
    }

    this.setState({ loading: true });

    const createProfilePlayerResponse = await metafabHelper.createProfilePlayer(game.id, profile, gameUsername);

    if (createProfilePlayerResponse.status !== 200) {
      this.setState({ loading: false });
      return alert(createProfilePlayerResponse.body);
    }

    const player = createProfilePlayerResponse.body;

    await this.pageContainer.hideContent();
    this.setShowState('showPermissions', { player });
    await this.pageContainer.showContent();
  }

  async toggleLoginRegister() {
    await this.pageContainer.hideContent();
    this.setShowState(this.state.showLogin ? 'showRegister' : 'showLogin');
    await this.pageContainer.showContent();
  }

  finalize(player) {
    let hash = `accessToken=${player.accessToken}&id=${player.id}&walletDecryptKey=${player.walletDecryptKey}`;

    if (queryState) {
      hash += `&state=${queryState}`;
    }

    if (queryRedirectUri) {
      window.location = `${queryRedirectUri}#${hash}`;
    } else {
      window.location.hash = hash;
    }
  }

  async setShowState(showKey, state = {}) {
    if (showKey === 'showPermissions') {
      const { profile, player, permissions } = { ...this.state, ...state };

      if (await permissionsHelper.compareApprovedPermissions(profile.id, player.id, permissions)) {
        return this.setState(state, this.finalize(player));
      }
    }

    this.setState({
      username: '',
      password: '',
      confirmPassword: '',
      showInit: false,
      showLogin: false,
      showRegister: false,
      showConnectWallet: false,
      showPermissions: false,
      loading: false,
      ...{ [showKey]: true, ...state },
    });
  }

  _renderLogin = () => {
    const { styles } = this.props;
    const { ecosystem, game, username, password, loading } = this.state;

    return (
      <>
        <RegularText>Sign in to {ecosystem.name} to play</RegularText>
        <HeadingText style={styles.headingText}>{game.name}</HeadingText>

        <TextField
          label={'Username'}
          onChangeText={username => this.setState({ username })}
          onSubmitEditing={() => this.login()}
          containerStyle={styles.field}
          value={username}
        />

        <TextField
          label={'Password'}
          onChangeText={password => this.setState({ password })}
          onSubmitEditing={() => this.login()}
          containerStyle={styles.field}
          secureTextEntry
          value={password}
        />

        <Button
          onPress={() => this.login()}
          buttonColor={ecosystem.primaryColorHex}
          loading={loading}
        >
          Sign in
        </Button>

        <TextButton onPress={() => this.toggleLoginRegister()} style={styles.textButton}>
          Need a {ecosystem.name} account? <Text style={styles.semiBoldText}>Sign up.</Text>
        </TextButton>
      </>
    );
  }

  _renderRegister = () => {
    const { styles } = this.props;
    const { ecosystem, game, username, password, confirmPassword, loading } = this.state;

    return (
      <>
        <RegularText>Sign up for {ecosystem.name} to play</RegularText>
        <HeadingText style={styles.headingText}>{game.name}</HeadingText>

        <TextField
          label={'Username'}
          onChangeText={username => this.setState({ username })}
          onSubmitEditing={() => this.register()}
          containerStyle={styles.field}
          value={username}
        />

        <TextField
          label={'Password'}
          onChangeText={password => this.setState({ password })}
          onSubmitEditing={() => this.register()}
          containerStyle={styles.field}
          secureTextEntry
          value={password}
        />

        <TextField
          label={'Confirm Password'}
          onChangeText={confirmPassword => this.setState({ confirmPassword })}
          onSubmitEditing={() => this.register()}
          containerStyle={styles.field}
          secureTextEntry
          value={confirmPassword}
        />

        <Button
          onPress={() => this.register()}
          buttonColor={ecosystem.primaryColorHex}
          loading={loading}
        >
          Sign up
        </Button>

        <TextButton onPress={() => this.toggleLoginRegister()} style={styles.textButton}>
          Already have a {game.name} account? <Text style={styles.semiBoldText}>Sign in.</Text>
        </TextButton>
      </>
    );
  }

  _renderPlayerRegister = () => {
    const { styles } = this.props;
    const { ecosystem, game, gameUsername, loading } = this.state;

    return (
      <>
        <RegularText>Choose a username for</RegularText>
        <HeadingText style={styles.headingText}>{game.name}</HeadingText>

        <TextField
          label={'Desired Username'}
          onChangeText={gameUsername => this.setState({ gameUsername })}
          onSubmitEditing={() => this.registerProfilePlayer()}
          containerStyle={styles.field}
          value={gameUsername}
        />

        <Button
          onPress={() => this.registerProfilePlayer()}
          buttonColor={ecosystem.primaryColorHex}
          loading={loading}
        >
          Continue
        </Button>

        <TextButton onPress={() => this.toggleLoginRegister()} style={styles.textButton}>
          <Text style={styles.semiBoldText}>Sign into</Text> another {ecosystem.name} account.
        </TextButton>
      </>
    );
  }

  _renderPermissions = () => {
    const { styles } = this.props;
    const { ecosystem, game, profile, permissionComponents, loading } = this.state;

    return (
      <>
        <RegularText>Hi {profile.username}, {game.name} wants you to:</RegularText>
        <HeadingText style={styles.headingText}>Grant Access</HeadingText>

        {this._renderPermission('View all assets in your wallet.')}
        {this._renderPermission('Mint and transfer assets to your wallet.')}
        {this._renderPermission(`Interact with any contracts or assets created by ${game.name}.`)}
        {permissionComponents.map(component => this._renderPermission(component))}
        <View style={styles.permissionsSpacing} />

        <View style={styles.row}>
          <Button
            onPress={() => this.toggleLoginRegister()}
            buttonColor={'#202020'}
            loading={loading}
          >
            Cancel
          </Button>

          <View style={{ width: 8 }} />

          <Button
            onPress={() => this.allowPermissions()}
            buttonColor={ecosystem.primaryColorHex}
            loading={loading}
          >
            Allow
          </Button>
        </View>

        <TextButton onPress={() => this.toggleLoginRegister()} style={styles.textButton}>
          <Text style={styles.semiBoldText}>Sign into</Text> another {ecosystem.name} account.
        </TextButton>
      </>
    );
  }

  _renderPermission = textOrComponent => {
    const { styles } = this.props;

    return (
      <View
        key={`permission_${Math.random()}`}
        style={styles.permission}
      >
        <Image
          source={require('../assets/images/green-checkmark.png')}
          resizeMode={'contain'}
          style={styles.checkmarkImage}
        />

        <RegularText style={styles.permissionText}>{textOrComponent}</RegularText>
      </View>
    );
  }

  render() {
    const { styles } = this.props;
    const { ecosystem, game, showInit, showLogin, showRegister, showPlayerRegister, showConnectWallet, showPermissions } = this.state;

    let iconImageUri;
    let coverImageUri;

    if (ecosystem && game) {
      iconImageUri = showLogin || showRegister ? ecosystem.iconImageUrl : game.iconImageUrl;
      coverImageUri = showLogin || showRegister ? ecosystem.coverImageUrl : game.coverImageUrl;
    }

    return (
      <PageContainer
        showInit={showInit}
        backgroundImageUri={!!ecosystem && ecosystem.coverImageUrl}
        ref={ref => this.pageContainer = ref}
      >
        <BrandedCard
          iconImageUri={iconImageUri}
          coverImageUri={coverImageUri}
        >
          <View style={styles.content}>
            {showLogin && this._renderLogin()}
            {showRegister && this._renderRegister()}
            {showPlayerRegister && this._renderPlayerRegister()}
            {showConnectWallet}
            {showPermissions && this._renderPermissions()}
          </View>
        </BrandedCard>

        <PoweredByLogo style={styles.poweredByLogo} />
      </PageContainer>
    );
  }
}

export default WithMobile(EcosystemOAuthScreen, mobile => ({
  headingText: {
    marginTop: 2,
    marginBottom: 15,
  },
  textButton: {
    marginTop: 10,
  },
  content: {
    marginTop: 20,
  },
  field: {
    marginBottom: 20,
  },
  semiBoldText: {
    fontFamily: 'SemiBold',
  },
  spacedWalletButton: {
    marginBottom: 10,
  },
  poweredByLogo: {
    bottom: 0,
    position: 'absolute',
    zIndex: -1,
  },
  disabled: {
    opacity: 0.5,
  },
  row: {
    flexDirection: 'row',
  },
  permission: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  permissionsSpacing: {
    marginBottom: 10,
  },
  permissionText: {
    marginTop: 2,
  },
  checkmarkImage: {
    height: 20,
    width: 20,
    marginRight: 6,
  },
}));

function _insertWebTags(game) {
  document.title = `Authorize ${game.name}`;

  _insertMetaTagName('twitter:card', 'summary_large_image');
  _insertMetaTag('twitter:title', `Authorize ${game.name}`);
  _insertMetaTag('twitter:image', game.coverImageUrl || 'https://metafab-uploads.s3.amazonaws.com/coverDefault.jpg');
  _insertMetaTag('og:title', game.name);
  _insertMetaTag('og:image', game.coverImageUrl);
  _insertMetaTag('og:description', `Login, register or connect your wallet to ${game.name}.`);

  document.querySelector("link[rel='shortcut icon']").href = game.iconImageUrl || 'https://metafab-uploads.s3.amazonaws.com/iconDefault.jpg';
  document.querySelectorAll("link[rel='icon']").forEach(icon => icon.href = game.iconImageUrl || 'https://metafab-uploads.s3.amazonaws.com/iconDefault.jpg');
}

function _insertMetaTagName(name, content) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('name', name);
  metaTag.content = content;
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}

function _insertMetaTag(property, content) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('property', property);
  metaTag.content = content;
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}
