import React, { Component } from 'react';
import { View, Animated, ActivityIndicator, StyleSheet } from 'react-native';

const defaultBackgroundImage = require('../assets/images/default-bg.png');

export default class PageContainer extends Component {
  state = {
    backgroundOpacity: new Animated.Value(0),
    contentOpacity: new Animated.Value(0),
  }

  async showBackground() {
    await this._toggleBackground(true);
  }

  async showContent() {
    await this._toggleContent(true);
  }

  async hideBackground() {
    await this._toggleBackground(false);
  }

  async hideContent() {
    await this._toggleContent(false);
  }

  async _toggleBackground(show) {
    return new Promise(resolve => {
      Animated.timing(this.state.backgroundOpacity, {
        toValue: show ? 0.2 : 0,
        duration: 500,
        useNativeDriver: true,
      }).start(resolve);
    });
  }

  async _toggleContent(show) {
    return new Promise(resolve => {
      Animated.timing(this.state.contentOpacity, {
        toValue: show ? 1 : 0,
        duration: 500,
        useNativeDriver: true,
      }).start(resolve);
    });
  }

  render() {
    const { showInit, backgroundImageUri, children } = this.props;
    const { backgroundOpacity, contentOpacity } = this.state;
    const backgroundImageSource = backgroundImageUri
      ? { uri: backgroundImageUri }
      : defaultBackgroundImage;

    return (
      <View style={styles.container}>
        {showInit && (
          <View style={styles.initContainer}>
            <ActivityIndicator size={'large'} color={'#FFFFFF'} />
          </View>
        )}

        <Animated.View style={[ styles.content, { opacity: contentOpacity } ]}>
          {children}
        </Animated.View>

        <Animated.Image
          source={backgroundImageSource}
          resizeMode={'cover'}
          blurRadius={20}
          style={[ styles.backgroundImage, { opacity: backgroundOpacity } ]}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backgroundImage: {
    ...StyleSheet.absoluteFillObject,
    zIndex: -2,
  },
  container: {
    backgroundColor: '#000000',
    flex: 1,
    paddingVertical: 20,
  },
  content: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  initContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
