import React, { Component } from 'react';
import * as Font from 'expo-font';
import { MobileProvider } from './src/mobile/Provider';
import EcosystemOAuthScreen from './src/screens/EcosystemOAuthScreen';
import GameAuthScreen from './src/screens/GameAuthScreen';

export default class App extends Component {
  state = {
    fontLoaded: false,
  }

  async componentDidMount() {
    await Font.loadAsync({
      Normal: require('./src/assets/fonts/Inter-Regular.ttf'),
      Bold: require('./src/assets/fonts/Inter-Bold.ttf'),
      SemiBold: require('./src/assets/fonts/Inter-SemiBold.ttf'),
    });

    _insertWebScripts();

    this.setState({ fontLoaded: true });
  }

  render() {
    const oauth = window.location.pathname.includes('oauth');

    return (
      <MobileProvider>
        {oauth && (
          <EcosystemOAuthScreen />
        )}

        {!oauth && (
          <GameAuthScreen />
        )}
      </MobileProvider>
    );
  }
}

const _insertWebScripts = () => {
  const gtagScript = document.createElement('script');
  gtagScript.type = 'text/javascript';
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-XT73M2HRZ7';
  gtagScript.async = true;

  document.head.appendChild(gtagScript);

  const gaScript = document.createElement('script');
  gaScript.type = 'text/javascript';
  gaScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-XT73M2HRZ7');
  `;

  document.head.appendChild(gaScript);
};
