import { Helper } from 'react-native-maestro';

const defaultApiHost = 'https://api.trymetafab.com';

export default class APIHelper extends Helper {
  static get instanceKey() {
    return 'apiHelper';
  }

  get(options) {
    return this._request({ ...options, method: 'GET' });
  }

  put(options) {
    return this._request({ ...options, method: 'PUT' });
  }

  post(options) {
    return this._request({ ...options, method: 'POST' });
  }

  patch(options) {
    return this._request({ ...options, method: 'PATCH' });
  }

  delete(options) {
    return this._request({ ...options, method: 'DELETE' });
  }

  async uploadFile(options) {
    const formData = new FormData();

    formData.append('uri', options.fileUri);
    formData.append(options.fileName, options.file);

    if (options.data) {
      Object.keys(options.data).forEach(key => {
        if (options.data[key] !== undefined) {
          formData.append(key, options.data[key]);
        }
      });
    }

    return this._request({
      ...options,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...options.headers,
      },
    });
  }

  async _request(options) {
    const host = options.host ? options.host : defaultApiHost;
    let requestUrl = host + options.path;

    options.headers = options.headers || {};
    options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';

    const requestOptions = {
      headers: options.headers,
      method: options.method,
      body: (options.headers['Content-Type'] === 'application/json') ? JSON.stringify(options.data) : options.data,
    };

    if (options.headers['Content-Type'] === 'multipart/form-data') {
      delete requestOptions.headers['Content-Type'];
    }

    if (options.queryParams) {
      const queryString = Object.keys(options.queryParams).reduce((accumulator, paramName) => {
        const paramValue = (typeof options.queryParams[paramName] === 'object')
          ? JSON.stringify(options.queryParams[paramName])
          : options.queryParams[paramName];

        accumulator.push(`${encodeURIComponent(paramName)}=${encodeURIComponent(paramValue)}`);

        return accumulator;
      }, []).join('&');

      requestUrl = `${requestUrl}?${queryString}`;
    }

    const response = await fetch(requestUrl, requestOptions);

    return {
      statusCode: response.status,
      body: (response.status !== 204) ? await response.json() : '',
    };
  }
}
