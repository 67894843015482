import React, { Component } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import { RegularText } from './';
import maestro from '../maestro';

const { uiHelper } = maestro.helpers;

export default class TextField extends Component {
  render() {
    const { label, containerStyle, ...props } = this.props;

    return (
      <View style={containerStyle}>
        <RegularText>{label}</RegularText>

        <TextInput
          style={styles.textInput}
          {...props}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textInput: {
    backgroundColor: uiHelper.isDarkMode() ? '#0B0B0B' : '#FFFFFF',
    borderColor: uiHelper.isDarkMode() ? '#292929' : 'rgba(32, 32, 32, 0.25)',
    borderRadius: 6,
    borderWidth: 1,
    color: uiHelper.isDarkMode() ? '#FFFFFF' : '#000000',
    fontFamily: 'SemiBold',
    height: 36,
    marginTop: 6,
    outlineColor: uiHelper.isDarkMode() ? 'transparent' : 'rgba(5, 135, 255, 0.25)',
    paddingLeft: 10,
    width: '100%',
  },
});
