import React, { Component } from 'react';
import { Image, StyleSheet } from 'react-native';

export default class PoweredByLogo extends Component {
  render() {
    const { style } = this.props;

    return (
      <Image
        source={require('../assets/images/powered-by.png')}
        resizeMode={'contain'}
        style={[ styles.image, style ]}
      />
    );
  }
}

const styles = StyleSheet.create({
  image: {
    height: 25,
    width: 100,
  },
});
