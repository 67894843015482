import React, { Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import maestro from '../maestro';

const { uiHelper } = maestro.helpers;
const defaultCoverImage = require('../assets/images/default-cover.png');
const defaultIconImage = require('../assets/images/default-icon.png');

export default class BrandedCard extends Component {
  render() {
    const { coverImageUri, iconImageUri, children } = this.props;
    const coverImageSource = coverImageUri ? { uri: coverImageUri } : defaultCoverImage;
    const iconImageSource = iconImageUri ? { uri: iconImageUri } : defaultIconImage;

    return (
      <View style={styles.card}>
        <View style={styles.outerContent}>
          <Image
            source={coverImageSource}
            resizeMode={'cover'}
            style={styles.coverImage}
          />

          <View style={styles.innerContent}>
            <View style={styles.iconImageContainer}>
              <Image
                source={iconImageSource}
                resizeMode={'cover'}
                style={styles.iconImage}
              />
            </View>

            {children}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    borderColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 30,
    borderWidth: 7.5,
    filter: 'drop-shadow(0px 27px 47px rgba(0, 0, 0, 0.17)) drop-shadow(0px 11.28px 19.6355px rgba(0, 0, 0, 0.122205)) drop-shadow(0px 6.0308px 10.4981px rgba(0, 0, 0, 0.101338)) drop-shadow(0px 3.38082px 5.88513px rgba(0, 0, 0, 0.085)) drop-shadow(0px 1.79553px 3.12555px rgba(0, 0, 0, 0.0686618)) drop-shadow(0px 0.747159px 1.30061px rgba(0, 0, 0, 0.0477948));',
    width: 380,
  },
  coverImage: {
    alignSelf: 'center',
    backgroundColor: '#000000',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: 150,
    width: 365,
  },
  iconImage: {
    height: 90,
    width: 90,
  },
  iconImageContainer: {
    borderColor: '#FFFFFF',
    borderRadius: 15,
    borderWidth: 2,
    boxShadow: '0px 9px 29px rgba(0, 0, 0, 0.09), 0px 3.75998px 12.1155px rgba(0, 0, 0, 0.0646969), 0px 2.01027px 6.47753px rgba(0, 0, 0, 0.0536497), 0px 1.12694px 3.63125px rgba(0, 0, 0, 0.045), 0px 0.598509px 1.92853px rgba(0, 0, 0, 0.0363503), 0px 0.249053px 0.802504px rgba(0, 0, 0, 0.0253031);',
    overflow: 'hidden',
    position: 'absolute',
    top: -60,
  },
  innerContent: {
    padding: 30,
    paddingBottom: 20,
  },
  outerContent: {
    backgroundColor: uiHelper.isDarkMode() ? '#100F0F' : '#FFFFFF',
    borderRadius: 20,
    flex: 1,
  },
});
