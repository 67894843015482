import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Helper } from 'react-native-maestro';
import { ethers } from 'ethers';

const CHAINS = {
  ETHEREUM: {
    id: 1,
    name: 'Ethereum',
    explorer: 'https://etherscan.io',
  },
  GOERLI: {
    id: 5,
    name: 'Ethereum Testnet',
    explorer: 'https://goerli.etherscan.io',
  },
  MATIC: {
    id: 137,
    name: 'Polygon',
    explorer: 'https://polygonscan.com',
  },
  MATICMUMBAI: {
    id: 80001,
    name: 'Polygon Testnet',
    explorer: 'https://mumbai.polygonscan.com',
  },
  ARBITRUM: {
    id: 42161,
    name: 'Arbitrum One',
    explorer: 'https://arbiscan.io',
  },
  ARBITRUMNOVA: {
    id: 42170,
    name: 'Arbitrum Nova',
    explorer: 'https://nova.arbiscan.io',
  },
  ARBITRUMGOERLI: {
    id: 421613,
    name: 'Arbitrum Testnet',
    explorer: 'https://goerli.arbiscan.io',
  },
  FANTOM: {
    id: 250,
    name: 'Fantom',
    explorer: 'https://ftmscan.com',
  },
  FANTOMTESTNET: {
    id: 4002,
    name: 'Fantom Testnet',
    explorer: 'https://testnet.ftmscan.com',
  },
};

const ECOSYSTEM_PERMISSIONS_SCOPES = {
  transfers: [
    'transfer', 'transferFrom', 'transferWithFee', 'transferWithFeeRef',
    'batchTransfer', 'batchTransferWithRefs', 'batchTransferWithFees',
    'batchTransferWithFeesRefs', 'withdraw', 'burn', 'burnWithFee',
    'safeTransferFrom', 'safeBatchTransferFrom', 'burnFromAddress',
    'burnBatchFromAddress', 'bulkSafeBatchTransferFrom',
  ],
  approvals: [
    'approve', 'increaseAllowance', 'decreaseAllowance',
    'setApprovalForAll',
  ],
};

const ECOSYSTEM_PERMISSIONS_SCOPES_READABLE = {
  transfers: 'Initiate transfers, spends or burns for contract',
  approvals: 'Approve contracts or wallets to use your assets for contract',
};

export default class PermissionsHelper extends Helper {
  static get instanceKey() {
    return 'permissionsHelper';
  }

  async rememberApprovedPermissions(profileId, playerId, permissions) {
    await AsyncStorage.setItem(`${profileId}-${playerId}`, JSON.stringify(permissions));
  }

  async compareApprovedPermissions(profileId, playerId, permissions) {
    const approvedPermissions = await AsyncStorage.getItem(`${profileId}-${playerId}`);

    return approvedPermissions === JSON.stringify(permissions);
  }

  async convertPermissionsToComponents(permissions) {
    const components = [];
    const contractAddresses = Object.keys(permissions);

    for (let i = 0; i < contractAddresses.length; i++) {
      const contractAddress = contractAddresses[i];

      if (contractAddress === 'version') {
        continue;
      }

      const contractPermissions = permissions[contractAddress];
      const { chain } = contractPermissions;

      Object.keys(contractPermissions).forEach(key => {
        if (key === 'scopes') {
          contractPermissions[key].forEach(scope => {
            components.push(<Text>{ECOSYSTEM_PERMISSIONS_SCOPES_READABLE[scope]}: {this._truncateAndLinkAddress(contractAddress, chain)}</Text>);
          });
        }

        if (key === 'functions') {
          components.push(<Text>Interact with contract {this._truncateAndLinkAddress(contractAddress, chain)} using the following contract functions: {contractPermissions[key].join(', ')}</Text>);
        }

        if (key === 'erc20Limit') {
          const erc20Amount = contractPermissions[key] === '*' ? <Text style={{ color: '#FF0000' }}>unlimited</Text> : <Text>up to a lifetime total of ${contractPermissions[key]}</Text>;
          components.push(<Text>Transfer, spend or burn a lifetime maximum of {erc20Amount} token for contract: {this._truncateAndLinkAddress(contractAddress, chain)}</Text>);
        }

        if (key === 'erc1155Limits') {
          const erc1155Limits = contractPermissions[key];

          if (erc1155Limits === '*') {
            components.push(<Text>Transfer, spend or burn any items or assets for contract: {this._truncateAndLinkAddress(contractAddress, chain)}</Text>);
          } else {
            const textAmounts = Object.keys(erc1155Limits).map(id => (
              <Text key={`${contractAddress}_${id}`}>
                {erc1155Limits[id] === '*' ? (
                  <Text style={{ color: '#FF0000' }}>unlimited </Text>
                ) : (
                  <Text>{erc1155Limits[id]} </Text>
                )}
                of {id === '*' ? <Text style={{ color: '#FF0000' }}>all item types</Text> : `item id ${id}`}
              </Text>
            ));

            components.push((
              <Text>
                Transfer, spend or burn a lifetime maximum of {textAmounts.reduce((p, c) => [ p, ', ', c ])} for contract: {this._truncateAndLinkAddress(contractAddress, chain)}
              </Text>
            ));
          }
        }
      });
    }

    return components;
  }

  validateEcosystemPermissions(permissionsObject) {
    if (typeof permissionsObject !== 'object') {
      throw new Error('permissions must be an object.');
    }

    const contractAddresses = Object.keys(permissionsObject);

    // validate version
    if (contractAddresses.length && (!permissionsObject.version || permissionsObject.version !== '1.0.0')) {
      throw new Error('Version specific for contract permissions is invalid. Must be one of allowed versions: 1.0.0');
    }

    contractAddresses.forEach(contractAddress => {
      if (contractAddress === 'version') {
        return;
      }

      if (!ethers.utils.isAddress(contractAddress)) {
        throw new Error(`${contractAddress} is not a valid contract address.`);
      }

      const contractPermissions = permissionsObject[contractAddress];

      if (contractPermissions === '*') {
        return;
      }

      // validate permissions type
      if (typeof contractPermissions !== 'object') {
        throw new Error('Contract specific permissions must be an object or wildcard (*).');
      }

      // valiate chain for contract address
      if (!contractPermissions.chain || !CHAINS[contractPermissions.chain]) {
        throw new Error(`Chain specified for contract address ${contractAddress} must be a valid supported chain (${Object.keys(CHAINS).join(', ')}.`);
      }

      // validate contract addresses
      if (contractAddress !== '*' && !ethers.utils.isAddress(contractAddress)) {
        throw new Error(`${contractAddress} is not a valid contract address or wildcard (*).`);
      }

      Object.keys(contractPermissions).forEach(key => {
        // validate contract properties
        if (![ 'chain', 'scopes', 'functions', 'erc20Limit', 'erc1155Limits' ].includes(key)) {
          throw new Error(`${key} is not a valid permissioning key.`);
        }

        // validate scopes data type
        if (key === 'scopes' && (!Array.isArray(contractPermissions[key]) || contractPermissions[key].some(v => !ECOSYSTEM_PERMISSIONS_SCOPES[v]))) {
          throw new Error('"scopes" permissions property must be an array of valid scopes.');
        }

        // validate functions data types

        if (key === 'functions' && (!Array.isArray(contractPermissions[key]) || contractPermissions[key].some(v => typeof v !== 'string'))) {
          throw new Error('"functions" permissions property must be an array containing only strings.');
        }

        // validate erc20Limit
        if (key === 'erc20Limit' && typeof contractPermissions[key] !== 'number' && contractPermissions[key] !== '*') {
          throw new Error('"erc20Limit" must be a number or wildcard (*)');
        }

        // validate erc1155Limits
        if (key === 'erc1155Limits' && (typeof contractPermissions[key] !== 'object' || Object.values(contractPermissions[key]).some(v => !Number.isInteger(v) && v !== '*'))) {
          throw new Error('"erc1155Limits must be an object with only numbers or wildcard (*) as values."');
        }
      });
    });
  }

  _truncateAndLinkAddress(address, chain) {
    return (
      <TouchableOpacity onPress={() => window.open(`${CHAINS[chain].explorer}/address/${address}`, '_blank')}>
        <Text style={{ color: '#4477DD' }}>0x{address.substring(2, 6)}...{address.substring(address.length - 4)}</Text>
      </TouchableOpacity>
    );
  }
}
