import React, { Component } from 'react';
import { Text, StyleSheet } from 'react-native';
import maestro from '../maestro';

const { uiHelper } = maestro.helpers;

export default class HeadingText extends Component {
  render() {
    const { style, children } = this.props;

    return (
      <Text style={[ styles.text, style ]}>{children}</Text>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    color: uiHelper.isDarkMode() ? '#FFFFFF' : '#000000',
    fontFamily: 'SemiBold',
    fontSize: 28,
  },
});
