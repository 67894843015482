import { Helper } from 'react-native-maestro';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class MetafabHelper extends Helper {
  static get instanceKey() {
    return 'metafabHelper';
  }

  async getEcosystem(ecosystemId) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.get({ path: `/v1/ecosystems/${ecosystemId}` });
  }

  async getGame(gameId) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.get({ path: `/v1/games/${gameId}` });
  }

  async createPlayer(gamePublishedKey, username, password) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.post({
      path: '/v1/players',
      headers: { 'X-Game-Key': gamePublishedKey },
      data: { username, password },
    });
  }

  async createProfile(ecosystemPublishedKey, username, password) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.post({
      path: '/v1/profiles',
      headers: { 'X-Ecosystem-Key': ecosystemPublishedKey },
      data: { username, password },
    });
  }

  async createProfilePlayer(gameId, profile, username) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.post({
      path: `/v1/profiles/${profile.id}/games/${gameId}/players`,
      headers: {
        'X-Authorization': profile.accessToken,
        'X-Wallet-Decrypt-Key': profile.walletDecryptKey,
      },
      data: { username },
    });
  }

  async loginPlayer(gamePublishedKey, username, password) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.get({
      path: '/v1/players/auth',
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        'X-Game-Key': gamePublishedKey,
      },
    });
  }

  async loginPlayerByService(gamePublishedKey, serviceName, serviceCredential, username = undefined) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.post({
      path: `/v1/players/auth/${serviceName}`,
      headers: { 'X-Game-Key': gamePublishedKey },
      data: { username, serviceCredential },
    });
  }

  async loginProfile(ecosystemPublishedKey, username, password) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.get({
      path: '/v1/profiles/auth',
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        'X-Ecosystem-Key': ecosystemPublishedKey,
      },
    });
  }

  async getProfilePlayers(gameId, profile) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.get({
      path: `/v1/profiles/${profile.id}/games/${gameId}`,
      headers: { 'X-Authorization': profile.accessToken },
    });
  }

  async authProfilePlayer(gameId, profile) {
    const { apiHelper } = this.maestro.helpers;

    const getProfilePlayersResponse = await this.getProfilePlayers(gameId, profile);

    if (getProfilePlayersResponse.statusCode !== 200 || !getProfilePlayersResponse.body.players.length) {
      return getProfilePlayersResponse;
    }

    const { username } = getProfilePlayersResponse.body.players[0];

    return apiHelper.get({
      path: `/v1/profiles/${profile.id}/games/${gameId}/players/auth`,
      headers: {
        'X-Authorization': profile.accessToken,
        'X-Wallet-Decrypt-Key': profile.walletDecryptKey,
        'X-Username': username,
      },
    });
  }

  async updateProfilePlayer(gameId, profile, player, update) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.patch({
      path: `/v1/profiles/${profile.id}/games/${gameId}/players/${player.id}`,
      headers: {
        'X-Authorization': profile.accessToken,
        'X-Wallet-Decrypt-Key': profile.walletDecryptKey,
      },
      data: update,
    });
  }

  async setPlayerConnectedWallet(player, signerAddress, nonce, signature, chain) {
    const { apiHelper } = this.maestro.helpers;

    return apiHelper.post({
      path: `/v1/players/${player.id}/wallets`,
      headers: { 'X-Authorization': player.accessToken },
      data: {
        address: signerAddress,
        nonce,
        signature,
        chain,
      },
    });
  }

  async setRememberedProfile(profile) {
    await AsyncStorage.setItem(`${window.location.host}-profile`, JSON.stringify(profile));
  }

  async getRememberedProfile() {
    try {
      return JSON.parse(await AsyncStorage.getItem(`${window.location.host}-profile`));
    } catch (error) {
      return undefined;
    }
  }

  validateRedirectUri(validRedirectUris, redirectUri) {
    let approvedUri = false;

    for (let i = 0; i < validRedirectUris.length; i++) {
      if (redirectUri.toLowerCase().includes(validRedirectUris[i].toLowerCase())) {
        approvedUri = true;
        break;
      }
    }

    return approvedUri;
  }
}
